<template>
  <div>
    {{ race.libelle }}
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    props: {
        object: {type: Object, required: true}
    },
    data(){
        return {

        }
    },
    computed: {
        ...mapGetters({
            races: 'identification/races'
        }),
        race(){
            return [...this.races].find(item => item.uid === this.object.race)
        }
    }

}
</script>